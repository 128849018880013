import React, { useEffect } from "react";
import "./FreeAlgoForm.css"; // Add custom CSS for styling

const FreeAlgoFormPage = () => {
  useEffect(() => {
    const form = document.querySelector(".ml-block-form");
    const title = document.querySelector(".ml-form-embedContent");
    const successMessage = document.querySelector(".row-success");

    if (form) {
      form.addEventListener("submit", async (event) => {
        event.preventDefault(); // Prevent default form submission behavior

        const formData = new FormData(form);
        const url =
          "https://assets.mailerlite.com/jsonp/1280755/forms/143879829872182340/subscribe";

        try {
          const response = await fetch(url, {
            method: "POST",
            body: formData,
          });

          if (response.ok) {
            // Hide the form and original title/description
            form.style.display = "none";
            if (title) title.style.display = "none";

            // Show the success message
            if (successMessage) successMessage.style.display = "block";
          } else {
            alert("There was an error submitting the form. Please try again.");
          }
        } catch (error) {
          console.error("Error:", error);
          alert("An unexpected error occurred. Please try again.");
        }
      });
    }
  }, []);

  return (
    <div className="form-page-container">
      {/* Embed the MailerLite form */}
      <div
        dangerouslySetInnerHTML={{
          __html: `
        <style type="text/css">@import url("https://assets.mlcdn.com/fonts.css?version=1734335");</style>
        <style type="text/css">
          .form-page-container {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100vh;
            background-color: #121212;
          }
          .ml-form-embedContainer {
            box-sizing: border-box;
            margin: 0 auto;
            max-width: 400px;
            padding: 20px;
            border-radius: 8px;
            background-color: #1f1f2e;
            color: #333333;
          }
          h4 {
            color: #a172dc;
            font-family: 'Montserrat', sans-serif;
            font-size: 30px;
            font-weight: 700;
          }
          p {
            color: #ffffff;
            font-family: 'Montserrat', sans-serif;
            font-size: 14px;
          }
          input {
            width: 100%;
            padding: 10px;
            margin-bottom: 10px;
            border-radius: 4px;
            border: 1px solid #cccccc;
            box-sizing: border-box;
          }
          button.primary {
            width: 100%;
            padding: 10px;
            border: none;
            border-radius: 4px;
            background-color: #c244c2;
            color: #ffffff;
            font-size: 16px;
            cursor: pointer;
          }
          button.primary:hover {
            background-color: #333333;
          }
        </style>
        <div id="mlb2-21756559" class="ml-form-embedContainer ml-subscribe-form ml-subscribe-form-21756559">
          <div class="ml-form-align-center">
            <div class="ml-form-embedWrapper embedForm">
              <div class="ml-form-embedBody ml-form-embedBodyDefault row-form">
                <div class="ml-form-embedContent">
                  <h4>Free NinjaTrader Algo</h4>
                  <p>Enter the information below to receive your free NinjaTrader algo</p>
                </div>
                <form class="ml-block-form" method="post">
                  <div class="ml-form-formContent">
                    <div class="ml-form-fieldRow">
                      <div class="ml-field-group ml-field-name">
                        <input aria-label="name" type="text" class="form-control" name="fields[name]" placeholder="Name" autocomplete="given-name">
                      </div>
                    </div>
                    <div class="ml-form-fieldRow ml-last-item">
                      <div class="ml-field-group ml-field-email ml-validate-email ml-validate-required">
                        <input aria-label="email" aria-required="true" type="email" class="form-control" name="fields[email]" placeholder="Email" autocomplete="email">
                      </div>
                    </div>
                  </div>
                  <div class="ml-form-embedSubmit">
                    <button type="submit" class="primary">Get My Free NinjaTrader Algo</button>
                  </div>
                </form>
              </div>
              <div class="ml-form-successBody row-success" style="display: none">
                <div class="ml-form-successContent">
                  <h4>Thank you!</h4>
                  <p>You should receive an email containing a link to download your free NinjaTrader algo.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      `,
        }}
      />
    </div>
  );
};

export default FreeAlgoFormPage;
